import { useServerDelegate } from "../UseServerDelegate";

export
class BaseServerApiDoc {
  async getDoc(type: string, user: string, id: string): Promise<Object | null> {
    const apiUrl = `https://mobu-user-file.s3.eu-north-1.amazonaws.com/${user}/${type}/${id}.json`;
    try {
      const response = await fetch(apiUrl, { cache: "no-store" });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      return await response.json();
    } catch (error) {
      console.error('Error:', error);
    }
    return null;
  }

  async updateDoc(type: string, id: string, data: string): Promise<boolean> {
    const {getToken} = useServerDelegate();
    const token = await getToken();
    if (!token) throw new Error("ServerApiDocPage.update: no valid token");

    const filename = id + '.json';
    const apiUrl = `https://mobu.app:3000/api/v1/user/${token.payload.sub}/doc/${type}/${filename}`;
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: data
      });
      return response.ok;
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  }
}
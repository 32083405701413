import { Amplify } from 'aws-amplify';
import { fetchAuthSession } from '@aws-amplify/auth';
import { JWT, signIn, type SignInInput, signOut } from 'aws-amplify/auth';
import Logger, { MessageLevel, MessageType } from "@/shared/Logger";

const region = "eu-north-1";
const userPoolId = "eu-north-1_t4SIAnJsr";
const clientId = "57bkvmllt9ohoht63q413vtt7p"; // Cognito - App integration - App client list
Amplify.configure({
  aws_project_region: region,
  // "aws_cognito_identity_pool_id": userPoolId,
  // "aws_cognito_region": region,
  aws_user_pools_id: userPoolId,
  aws_user_pools_web_client_id: clientId,
  oauth: {},
} as any);

export function useServerDelegate() {
  // https://docs.amplify.aws/gen1/vue/build-a-backend/auth/enable-sign-up/
  async function userSignIn({ username, password }: SignInInput, onFinished: ()=>void) {
    await userSignOut(()=>{});
    try {
      const { isSignedIn, nextStep } = await signIn({ username, password });
      isSignedIn;
      nextStep;
      onFinished();
    } catch (error) {
      console.log('error signing in', error);
    }
  }

  async function userSignOut(onFinished: ()=>void) {
    try {
      await signOut();
      onFinished();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  async function getToken() {
    try {
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
      idToken;
      Logger.log(MessageLevel.Info, MessageType.Network, 'getToken:' + JSON.stringify(accessToken?.payload));
      return accessToken;
    } catch (err) {
      console.log(err);
      throw err; // Ensure that the error is thrown so that it can be handled by the caller
    }
  }

  async function getUserSubdomainInfo(subdomain: string, onFinished: (value: Object) => void, onError: (error: any) => void) {
    const apiUrl = `https://mobu.app:3000/api/v1/subdomain/${subdomain}`;
    fetch(apiUrl, { cache: "no-store" }).then(response => {
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      return response.json();
    }).then(data => {
      onFinished(data);
    }).catch(error => {
      if (onError){
        onError(error);
      }
      return null;
    });
  }

  return {
    userSignIn,
    userSignOut,
    getToken,

    getUserSubdomainInfo,
  };
}
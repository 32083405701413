import { BaseServerApiDoc } from "./BaseServerApiDoc";
import { App } from "@/components/app/app/App";

export default class ServerApiDocApp extends BaseServerApiDoc {
  async get(user: string, id: string): Promise<App | null> {
    try {
      const data = await super.getDoc('app', user, id);
      if (!data) return null;
      const app = new App;
      app.fromStoreObject(data);
      return app;
    } catch (error) {
      return null;
    }
  }

  async update(id: string, app: App): Promise<boolean> {
    return super.updateDoc('app', id, JSON.stringify(app.getStoreObject()));
  }

  async create(id: string, app: App): Promise<boolean> {
    return await this.update(id, app);
  }
}
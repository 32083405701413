import { BaseServerApiDoc } from "./BaseServerApiDoc";
import { Page } from "@/components/page/page/Page";

export default class ServerApiDocPage extends BaseServerApiDoc {
  async get(user: string, id: string): Promise<Page | null> {
    try {
      const data = await super.getDoc('page', user, id);
      if (!data) return null;
      const page = new Page;
      page.fromStoreObject(data);
      return page;
    } catch (error) {
      return null;
    }
  }

  async update(id: string, page: Page): Promise<boolean>{
    return super.updateDoc('page', id, JSON.stringify(page.getStoreObject()));
  }

  async create(id: string, page: Page): Promise<boolean>{
    return await this.update(id, page);
  }
}
import { BaseItem, MetaInfo } from "@/components/shared/model/BaseItem";
import { Spacer } from "@/components/elemental/spacer/Spacer";
import { typeToItem } from "@/components/shared/registry/All";

export
enum ChangeType {
  ContainerResizedPassively,
  RatioUpdated,
  HeightUpdatedWithRatio,
  HeightModeChanged,
  MinContentHeight,
  Text,
  Anchor,
  DraggingPositionOrSize,
  DraggingFinished
}

export
class Rect{
  x = 0;
  y = 0;
  width = 0;
  height = 0;

  constructor (x: number = 0, y: number = 0, width: number = 0, height: number = 0){
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
  }

  private limitDecimalPlaces(num: number, decimalPlaces: number): number {
    return parseFloat(num.toFixed(decimalPlaces));
  }

  public getStoreObject(): Object{
    // desired
    const decimalPlaces = 4;
    return {
      x: this.limitDecimalPlaces(this.x, decimalPlaces),
      y: this.limitDecimalPlaces(this.y, decimalPlaces),
      width: this.limitDecimalPlaces(this.width, decimalPlaces),
      height: this.limitDecimalPlaces(this.height, decimalPlaces)
    };
  }

  public fromStoreObject(object: any){
    this.x= object.x;
    this.y= object.y;
    this.width= object.width;
    this.height= object.height;
  }

  equals(rect: Rect){
    return Math.abs(rect.x - this.x)<0.01 &&
           Math.abs(rect.y - this.y)<1 &&
           Math.abs(rect.width - this.width)<0.01 &&
           Math.abs(rect.height - this.height)<1;
  }

  from(rect: Rect){
    this.x = rect.x;
    this.y = rect.y;
    this.width = rect.width;
    this.height = rect.height;
  }

  clone(){
    const newRect = new Rect;
    newRect.from(this);
    return newRect;
  }
}

export
enum AnchorPosition{
  Top = "Top",
  Center = "Center",
  Bottom = "Bottom",
  None = "None" // For root FluidElement
}

export
enum HeightMode {
  Min = "Min",
  FitContent = "FitContent",
  AspectRatio = "AspectRatio",
  InheritProportionally = "InheritProportionally",
}

export
class Spatial{
  desired = new Rect;
  displayed = new Rect;
  manipulated = new Rect;
  z = 0;
  contentHeight = 0; // content height, set in edit mode, triggered by layoutMinHeightChanged
  contentWidth = 0;

  heightMode = HeightMode.FitContent;

  aspectRatio = 1;
  sourceAnchorVerticalPosition : AnchorPosition = AnchorPosition.None;
  targetAnchorVerticalPosition : AnchorPosition = AnchorPosition.None;

  public getStoreObject(): Object{
    return {
      desired: this.desired.getStoreObject(),
      // displayed: this.displayed.getStoreObject(),
      // manipulated: this.manipulated.getStoreObject(),
      z: this.z,
      heightMode: this.heightMode,
      aspectRatio: this.aspectRatio,
      // contentHeight: this.contentHeight,
      // contentWidth: this.contentWidth,
      sourceAnchorVerticalPosition: this.sourceAnchorVerticalPosition,
      targetAnchorVerticalPosition: this.targetAnchorVerticalPosition,
    };
  }

  public fromStoreObject(object: any){
    this.desired.fromStoreObject(object.desired);
    // this.displayed.fromStoreObject(object.displayed);
    // this.manipulated.fromStoreObject(object.manipulated);
    this.z = object.z;
    this.contentHeight = 0;
    this.contentWidth = 0;
    this.aspectRatio = object.aspectRatio === undefined ? 1 : object.aspectRatio;
    this.heightMode = object.heightMode!==undefined ? object.heightMode : HeightMode.Min;
    if (object.sourceAnchorVerticalPosition in AnchorPosition){
      this.sourceAnchorVerticalPosition = object.sourceAnchorVerticalPosition;
    }
    if (object.targetAnchorVerticalPosition in AnchorPosition){
      this.targetAnchorVerticalPosition = object.targetAnchorVerticalPosition;
    }
  }

  constructor (desired: Rect, displayed: Rect, contentHeight: number = 0, contentWidth: number = 0, heightMode = HeightMode.Min){
    this.desired = desired;
    this.displayed = displayed;
    this.z = 0;
    this.contentHeight = contentHeight;
    this.contentWidth = contentWidth;
    this.heightMode = heightMode;
  }

  ifShouldStickToAspectRatio(){
    return this.heightMode === HeightMode.AspectRatio;
  }

  stickToAspectRatio(width: number){
    if (this.ifShouldStickToAspectRatio()){
      this.displayed.height = width * this.aspectRatio;
    }
  }

  ifShouldStickToContentHeight(){
    return !this.ifShouldStickToAspectRatio() && this.heightMode==HeightMode.FitContent && this.contentHeight>0;
  }

  stickToContentHeight(){
    this.displayed.height = this.contentHeight;
  }

  iterateHeightMode(){
    switch (this.heightMode) {
      case HeightMode.Min:
        if (this.contentHeight>0){
          this.heightMode = HeightMode.FitContent;
        } else {
          this.heightMode = HeightMode.AspectRatio;
          if (this.aspectRatio === 0){
            this.aspectRatio = 1;
          }
        }
        break;
      case HeightMode.FitContent:
        this.heightMode = HeightMode.AspectRatio;
        if (this.aspectRatio === 0){
          this.aspectRatio = 1;
        }
        break;
      case HeightMode.AspectRatio:
        if (this.targetAnchorVerticalPosition !== AnchorPosition.None){
          this.heightMode = HeightMode.InheritProportionally;
        } else {
          this.heightMode = HeightMode.Min
        }
        break;
      case HeightMode.InheritProportionally:
        this.heightMode = HeightMode.Min;
        break;
      default:
        this.heightMode = HeightMode.Min;
        break;
    }
  }

  setDisplayHeightSafely(height: number, width: number){
    if (this.ifShouldStickToAspectRatio()){
      this.stickToAspectRatio(width);
      return;
    }
    if (this.ifShouldStickToContentHeight()){
      this.stickToContentHeight();
      return;
    }
    this.displayed.height = Math.max(height, this.contentHeight);
  }

  clone(): Spatial{
    const newSpatial = new Spatial(this.desired.clone(), this.displayed.clone(), this.contentHeight, this.contentWidth, this.heightMode);
    newSpatial.manipulated = this.manipulated.clone();
    newSpatial.sourceAnchorVerticalPosition = this.sourceAnchorVerticalPosition;
    newSpatial.targetAnchorVerticalPosition = this.targetAnchorVerticalPosition;
    newSpatial.z = this.z;
    return newSpatial;
  }

  equals(spatial: Spatial){
    return spatial.desired.equals(this.desired) &&
           spatial.displayed.equals(this.displayed) &&
           spatial.manipulated.equals(this.manipulated) &&
           spatial.z === this.z &&
           spatial.contentHeight === this.contentHeight &&
           spatial.contentWidth === this.contentWidth &&
           spatial.heightMode == this.heightMode &&
           spatial.sourceAnchorVerticalPosition === this.sourceAnchorVerticalPosition &&
           spatial.targetAnchorVerticalPosition === this.targetAnchorVerticalPosition
  }
}

export
class FluidElement extends BaseItem{
  static readonly meta: MetaInfo = {typeName: "FluidElement", friendlyName: "Fluid Element"};

  // desired spatial.
  spatial: Spatial = new Spatial(new Rect,new Rect,0,0);
  item: BaseItem = new Spacer();

  public getStoreObjectItem(): Object{
    return {
      spatial: this.spatial.getStoreObject(),
      item: this.item.getStoreObject()
    };
  }

  public fromStoreObject(object: any): void {
    this.item = typeToItem(object.item.item.type);
    super.fromStoreObject(object);
  }

  public fromStoreObjectItem(item: any){
    this.spatial.fromStoreObject(item.spatial);
    this.item.fromStoreObject(item.item);
  }

  constructor (spatial: Spatial = new Spatial(new Rect,new Rect,0,0), item: BaseItem = new Spacer()){
    super();
    this.spatial = spatial;
    this.item = item;
  }

  public override from(item: BaseItem): void{
    super.from(item);
    const fluidElement = item as FluidElement;
    this.spatial = fluidElement.spatial.clone();
    this.item = fluidElement.item.clone(); // must be clone because the type changes
  }

  getMeta(): MetaInfo{
    return FluidElement.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new FluidElement(new Spatial(new Rect,new Rect,0,0), new Spacer());
  }

  public contains(item: BaseItem): BaseItem[]{
    if (this.item.ref === item.ref){
      return [this.item, this];
    }

    const tmp = this.item.contains(item);
    if (tmp.length>0){
      tmp.push(this.item);
      tmp.push(this);
      return tmp;
    }
    return [];
  }
}